import { inject, Injectable } from '@angular/core';
import { Upsell } from '@core/models';
import { Subject } from 'rxjs';
import { PrivateSellingService } from '../../../services/private-selling.service';

@Injectable({
  providedIn: 'root',
})
export class UpsellsService {
  private privateSellingService = inject(PrivateSellingService);

  upsells: Upsell[];
  totalCartAndFiltersSeats$ = new Subject<number>(); // Cada vez que cambia (se hace un ".next") se lanza el this.checkTotalSeatsForUpsells(totalCartSeats) desde upsells.component.ts
  upsellsCall: ReturnType<typeof setTimeout>;

  reachingUpsell: Upsell | null = null;
  acquiredUpsell: Upsell | null = null;

  availableUpsells: Upsell[] = [];
  acquiredUpsells: Upsell[] = [];
  previousTotalSeats: number;

  sendLastUpsellsCall(numSeats: number) {
    if (this.upsellsCall) {
      clearTimeout(this.upsellsCall);
    }
    // Updating totalCartAndFiltersSeats number for Upsells
    this.upsellsCall = setTimeout(() => {
      this.totalCartAndFiltersSeats$.next(numSeats);
    }, 1000);
  }

  sortUpsellsByGoal(upsells: Upsell[]) {
    if (upsells?.length) {
      upsells.sort((a, b) => a.goal - b.goal);
    }
  }

  checkCartSeatsForUpsellsList(totalCartSeats: number) {
    if (!this.upsells?.length) return;

    this.sortUpsellsByGoal(this.upsells);
    // Reset Upsells lists, available and acquired
    this.availableUpsells = [];
    this.acquiredUpsells = [];

    for (const upsell of this.upsells) {
      // Set Upsells lists, available and acquired
      if (upsell.goal <= totalCartSeats) {
        this.acquiredUpsells.push(upsell);
      } else {
        this.availableUpsells.push(upsell);
      }
    }
  }

  checkTotalSeatsForUpsellsMessages(totalSeats: number) {
    if (!this.upsells?.length) return;
    if (
      this.privateSellingService.isEnabled() &&
      !this.privateSellingService.isUserInWhitelist()
    ) {
      return;
    }

    this.sortUpsellsByGoal(this.upsells);

    let availableUps = [];
    let acquiredUps = [];
    for (const upsell of this.upsells) {
      // Set Upsells lists, available and acquired
      if (upsell.goal <= totalSeats) {
        acquiredUps.push(upsell);
      } else {
        availableUps.push(upsell);
      }
    }

    for (const upsell of this.upsells) {
      // Si el num seats coincide con un Goal
      if (upsell.goal === totalSeats) {
        this.acquiredUpsell = upsell;
        setTimeout(() => (this.acquiredUpsell = null), 3000);
        // Si el num seats coincide con un minSeatsToShow
      } else if (upsell.minSeatsToShow === totalSeats) {
        this.reachingUpsell = upsell;
        setTimeout(() => (this.reachingUpsell = null), 3000);
      }
    }

    // Si el num seats tiene una diferencia con el num previo de más de 1 (se ha introducido por el input manualmente)
    if (
      this.previousTotalSeats - totalSeats < -1 ||
      this.previousTotalSeats - totalSeats > 1
    ) {
      if (availableUps[0] && availableUps[0].minSeatsToShow < totalSeats) {
        this.reachingUpsell = availableUps[0];
        setTimeout(() => (this.reachingUpsell = null), 3000);
      }
    }

    // Store previous seats count
    this.previousTotalSeats = totalSeats;
  }
}
